.modal-overlay {
  position: fixed; /* Sit on top of the page content */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
  overflow: auto;
}

.modal-content {
  background-color: #fff; 
  padding: 20px; 
  border-radius: 5px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 50%; 
  max-width: 600px; 
  z-index: 1001;
  max-height: 80vh; /* limit the height of the modal to 80% of the viewport height */
  overflow-y: auto; /* add a scrollbar when the content overflows */

}

.close-button {
  float: right; 
  font-size: 1.2rem; 
  border: none; 
  background: none; 
  cursor: pointer;
}

