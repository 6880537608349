/* SearchInput.css */
.input-group {
    width: auto; /* Adjusted from 35% to auto for flexibility */
    height: 100%;
    background-color: #fff5;
    padding: 0 .8rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
}

.input-group:hover {
    background-color: #fff8;
    box-shadow: 0 .1rem .4rem #0002;
}

.input-group input {
    width: 100%;
    height: 40px; /* Ensure height matches design specifications */
    padding: 0 .5rem 0 .3rem;
    background-color: transparent;
    border: none;
    outline: none;
    font-family: sans-serif; /* Ensures font consistency */
}
