*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}
body{
    min-height:100vh;
    display: flex;
    background: url(../../assets/GeorgeWashington.jpeg) center/cover;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

  

.table_body::-webkit-scrollbar{
    width: 0.5rem;
    height: 0.5rem;
}

.table_body::-webkit-scrollbar-thumb{
    border-radius: 0.5rem;
    background-color: #0004;
    visibility: hidden;
}


.table_body:hover::-webkit-scrollbar-thumb{
    visibility: visible;
}

table{
    width: 100%;
}

table,th,td{
    border-collapse: collapse;
    padding: 1rem;
    text-align: left;
}

thead th{
    position:sticky;
    top: 0;
    left: 0;
    background-color: #d5d1defe;
}
main.table{
    width: 98vw;
    height: 96vh;
    background-color: #fff5;
    backdrop-filter: blur(7px);
    box-shadow: 0 .4rem 8rem #0005;
    border-radius: .8rem;
    overflow: hidden;
}

.table_header{
    width: 100%;
    height: 10%;
    background-color: #fff4;
    padding: .8rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.table_header .input-group{
    width: 35%;
    height: 100%;
    background-color: #fff5;
    padding: 0 .8rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
}

.table_header .input-group:hover{
    background-color: #fff8;
    box-shadow: 0 .1rem .4rem #0002;
}



.input-group input {
    height: 40px;
  }
.header-controls {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.table_header .input-group input{
    width: 100%;
    padding: 0 .5rem 0 .3rem;
    background-color: transparent;
    border: none;
    outline: none;
}
td img{
    width: 36px;
    height: 36px;
    margin-right: .5rem;
    border-radius: 50%;
    vertical-align: middle;
}
.action-button{
    background-color: #454749;
    border: none;
    color: white;
    padding: 10px;
    height: 40px;
    border-radius: 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    margin: 4px 2px;
    margin-left: 1rem
}


.table_body{
    width: 95%;
    max-height: calc(89% - 1.6rem);
    background-color:#fffb;
    margin:.8rem auto;
    border-radius: .6rem;
    overflow: auto;
    overflow: overlay;
}

tbody tr:nth-child(even){
    background-color: #0000000b;
}

tbody tr.hide{
    opacity: 0;
}

tbody tr{
    --delay: .1s;
    transition: .5s ease-in-out var(--delay), background-color 0s;
}

tbody tr:hover{
    background-color: #fff6 !important;
}

tbody tr td,
tbody tr td p,
tbody tr td{
    transition: .2s ease-in-out;
}

tbody tr hide td,
tbody tr.hide td p{
    padding: 0;
    font: 0 / 0 sans-serif;
    transition: .2s ease-in-out .5s;
}

tbody tr.hide td{
    width: 0;
    height: 0;
    transition: .2s ease-in-out .5s;
}
.table_header .input-group {
    width: auto; 
    margin-right: 1rem; 
}
@media screen and (max-width: 1000px){
    td:not(:first-of-type){
        min-width: 12.1rem;
    }
    
}

.data-display-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust the gap as needed */
  }

.date-inputs{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.time-inputs{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.request-list {
    list-style-type: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
}

.request-item {
    margin-bottom: 1rem; /* Add some spacing between list items */
}