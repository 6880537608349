

table,th,td{
    border-collapse: collapse;
    padding: 1rem;
    text-align: left;
}

.table_body:hover::-webkit-scrollbar-thumb{
    visibility: visible;
}



table{
    width: 100%;
}

main.table{
    
    width: 98%;
    height: 96vh;
    background-color: #fff5;
    backdrop-filter: blur(7px);
    box-shadow: 0 .4rem 8rem #0005;
    border-radius: .8rem;
    overflow: hidden;
}

tbody tr:nth-child(even){
    background-color: #0000000b;
}

tbody tr.hide{
    opacity: 0;
}

tbody tr{
    --delay: .1s;
    transition: .5s ease-in-out var(--delay), background-color 0s;
}

tbody tr:hover{
    background-color: #fff6 !important;
}

tbody tr td,
tbody tr td p,
tbody tr td{
    transition: .2s ease-in-out;
}

tbody tr hide td,
tbody tr.hide td p{
    padding: 0;
    font: 0 / 0 sans-serif;
    transition: .2s ease-in-out .5s;
}

tbody tr.hide td{
    width: 0;
    height: 0;
    transition: .2s ease-in-out .5s;
}

thead th{
    position:sticky;
    top: 0;
    left: 0;
    background-color: #d5d1defe;
}


.no-data-message {
    text-align: center;
    font-size: 1.5em;
    color: #555;
}